import create from "zustand";

type TSeverity = "success" | "error" | "info" | "warning";

interface ISnackbarProps {
  open: boolean;
  message: string;
  severity: TSeverity;
  showSnackbar: (message: string, severity: TSeverity) => void;
  hideSnackbar: () => void;
}

export const useSnackbarStore = create<ISnackbarProps>((set) => ({
  open: false,
  message: "",
  severity: "info",
  showSnackbar: (message, severity) => set({ open: true, message, severity }),
  hideSnackbar: () => set({ open: false, message: "", severity: "info" }),
}));
