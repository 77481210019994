import storage from "@/lib/storage";
import { createContext, useState } from "react";

export type TagContextContent = {
  pageDataLayer: any;
  setExtraPageDataLayer: any;
};

export const GlobalTagContext = createContext<TagContextContent>({
  pageDataLayer: {},
  setExtraPageDataLayer: void 0,
});

export default function GlobalGTMTagProvider(props: any) {
  const user = storage.getUser();
  const role = storage.getRole();
  const isFranchise = storage.utilities.isFranchise();

  const { children } = props;

  const userId = isFranchise ? user?.company?.id : user?.id;
  const userName = isFranchise
    ? user?.company?.name
    : `${user?.firstname} ${user?.lastname}`;
  const userRole = role;
  const userEmail = isFranchise ? user?.company?.email : user?.email;

  const [pageDataLayer, setPageDataLayer] = useState({
    user_id: userId,
    env_site: "dealerplus",
    user_email: userEmail,
    username: userName,
    user_role: userRole,
  });

  const setExtraPageDataLayer = (info: {}) => {
    setPageDataLayer({ ...pageDataLayer, ...info });
  };

  return (
    <GlobalTagContext.Provider value={{ pageDataLayer, setExtraPageDataLayer }}>
      {children}
    </GlobalTagContext.Provider>
  );
}
