import { useState } from "react";
import { Tag } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import client from "@/lib/axios-client";
import tw from "tailwind-styled-components";
import { CheckCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface INotifyCard {
  item: INotification;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

const Message = tw.div`text-sm`;

export default function NotificationCard({
  item,
  setShowNotification,
}: Readonly<INotifyCard>) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});

  const handleMarkAsReadForCustomerLoans = async (id: string) => {
    const payload = {
      id: id,
      is_read: true,
    };
    try {
      const res = await client.put(`/v2/origination/notifications`, payload);
      if (res) {
        queryClient.invalidateQueries(["fetch_in_app_notifications"]);
        setLoading((prev) => ({ ...prev, [id]: true }));
      }
    } finally {
      setLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleGoToLoan = (loanId: string) => {
    navigate(`/applications/loans/${loanId}`);
    setShowNotification(false);
  };

  return (
    <div className="relative flex flex-col md:flex-row gap-2 px-3 md:px-4 py-4 w-full hover:bg-slate-100 group">
      <div className="flex flex-col gap-2 w-full">
        <Message
          className={`${
            item?.isRead
              ? "font-medium text-[#8f8f8f]"
              : "font-semibold text-[#30345E]"
          } w-full md:w-[65%]`}
        >
          {item?.message}
        </Message>
        <div className="flex flex-col gap-0.5">
          {item?.firstName && (
            <div className="text-sm text-[#8f8f8f] font-semibold mb-0.5">
              {item?.firstName ? `${item?.firstName} ${item?.lastName}` : "---"}
            </div>
          )}
          <div className="flex gap-2">
            {item?.loanStatus && (
              <Tag color="processing" className="uppercase mr-0">
                {item?.loanStatus?.replaceAll("_", " ")}
              </Tag>
            )}
            <Tag color="geekblue" className="uppercase mr-0">
              {item?.loanId}
            </Tag>
          </div>
        </div>
      </div>
      <div className="relative md:absolute right-0 top-0 md:p-4 xl:opacity-0 xl:group-hover:opacity-100 transition-opacity duration-300">
        <div className="flex gap-1">
          <button
            onClick={() => handleMarkAsReadForCustomerLoans(item?.id)}
            className="bg-white text-[#30345e] whitespace-nowrap hover:text-white hover:bg-[#30345e] border border-solid border-[#30345e] text-xs flex items-center gap-1 px-1.5 h-[22px] font-semibold rounded-[4px]"
          >
            {!loading[item?.id] ? (
              <CheckCircleOutlined />
            ) : (
              <CheckCircleFilled />
            )}{" "}
            Mark as read
          </button>
          <button
            onClick={() => {
              handleGoToLoan(item?.loanId);
            }}
            className="flex items-center gap-1 whitespace-nowrap min-w-[68.05px] text-xs bg-[#ffb619] text-[#30345e] px-3 h-[22px] font-semibold rounded-[4px]"
          >
            View{""}
            <img
              src="https://ik.imagekit.io/eo0k4ohmt/dealer-plus/deals/upper-right-arrow-square-button-outlined-symbol.png?updatedAt=1722001129924"
              alt="View"
              width="14px"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
