import { itemStyle } from "./menus/newMenus";
import type { MenuProps } from "antd";
import { Link } from "react-router-dom";

interface ISupportMenuProps {
  t: (key: string) => string;
  isDealer?: boolean;
  country?: string;
  isDealerDsa?: boolean;
  isAccountManager?: boolean;
}

const allCountries = ["NG", "KE", "UG", "GH", "CI", "SN"];

export const supportMenus = ({
  t,
  country,
  isAccountManager,
  isDealerDsa,
}: ISupportMenuProps) => {
  const supportItems: MenuProps["items"] = [
    {
      label: (
        <Link
          to={"/loan-calculator"}
          style={itemStyle}
          className="capitalize md:uppercase"
        >
          {t("loan-calculator")}
        </Link>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link
          to={"/prequalification"}
          style={itemStyle}
          className="capitalize md:uppercase"
        >
          {t("prequalification")}
        </Link>
      ),
      key: "1",
    },
  ];
  if (["NG"].includes(country as string) && isAccountManager) {
    supportItems.push(
      {
        type: "divider",
      },
      {
        label: (
          <Link
            to={"/dealer/leads"}
            style={itemStyle}
            className="capitalize md:uppercase"
          >
            {t("my leads")}
          </Link>
        ),
        key: "2",
      }
    );
  }
  if (isAccountManager) {
    supportItems.push(
      {
        type: "divider",
      },
      {
        label: (
          <Link
            to={"/manage/dealers"}
            style={itemStyle}
            className="capitalize md:uppercase"
          >
            {t("my-dealers")}
          </Link>
        ),
        key: "4",
      }
    );
  }
  return supportItems;
};

export const menus = [
  {
    title: "dashboard",
    path: "/dashboard",
    new: false,
    country: allCountries,
  },
  {
    title: "applications",
    path: "/applications",
    new: false,
    country: allCountries,
  },
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
    country: allCountries,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
    country: allCountries,
  },
];

export const dealerFinanceManagerMenu = (t: (key: string) => string) => {
  return [
    {
      title: "dashboard",
      path: "/dashboard",
      new: false,
      country: allCountries,
    },
    {
      title: "applications",
      path: "/applications",
      new: false,
      country: allCountries,
    },
    {
      title: "financing",
      path: "/financing",
      new: true,
      country: allCountries,
    },
    {
      title: "inventory",
      path: "/inventory",
      new: false,
      country: allCountries,
    },
    {
      title: "More",
      path: "",
      new: false,
      country: allCountries,
      subMenus: supportMenus({ t }),
    },
  ];
};

export const otherRoleMenus = (
  t: (key: string) => string,
  country: string,
  isAccountManager: boolean
) => {
  return [
    {
      title: "dashboard",
      path: "/dashboard",
      new: false,
      country: allCountries,
    },
    {
      title: "applications",
      path: "/applications",
      new: false,
      country: allCountries,
    },
    {
      title: "financing",
      path: "/financing",
      new: false,
      country: ["NG", "KE", "UG"],
    },
    {
      title: "inventory",
      path: "/inventory",
      new: false,
      country: allCountries,
    },
    {
      title: "my leads",
      path: "/dealer/leads",
      new: true,
      country: ["GH", "KE"],
    },
    {
      title: "insurance",
      path: "/insurance",
      new: false,
      country: ["NG"],
    },
    {
      title: "More",
      path: "",
      new: false,
      country: allCountries,
      subMenus: supportMenus({ t, country, isAccountManager }),
    },
  ];
};

export const dealerDsaMenus = (
  t: (key: string) => string,
  isDealerDsa: boolean
) => {
  return [
    {
      title: "dashboard",
      path: "/dashboard",
      new: false,
      country: allCountries,
    },
    {
      title: "applications",
      path: "/applications",
      new: false,
      country: allCountries,
    },
    {
      title: "inventory",
      path: "/inventory",
      new: false,
      country: allCountries,
    },
    {
      title: "my leads",
      path: "/dealer/leads",
      new: true,
      country: ["NG", "GH", "KE"],
    },
    {
      title: "insurance",
      path: "/insurance",
      new: false,
      country: ["NG"],
    },
    {
      title: "More",
      path: "",
      new: false,
      country: allCountries,
      subMenus: supportMenus({ t, isDealerDsa }),
    },
  ];
};

export const otherRoleMenusDF = (isMobile?: boolean) => {
  const baseMenus = [
    {
      title: "dashboard",
      path: "/dashboard",
      new: false,
    },
    {
      title: "applications",
      path: "/applications",
      new: false,
    },
    {
      title: "financing",
      path: "/financing",
      new: false,
    },
    {
      title: "inventory",
      path: "/inventory",
      new: false,
    },
    {
      title: "insurance",
      path: "/insurance",
      new: true,
    },
  ];

  if (isMobile) {
    baseMenus.push(
      {
        title: "loan-calculator",
        path: "/loan-calculator",
        new: false,
      },
      {
        title: "prequalification",
        path: "/prequalification",
        new: false,
      }
    );
  }

  return baseMenus;
};

export const franchiseMenus = (country: string, isMobile?: boolean) => {
  const baseMenus = [
    {
      title: "dashboard",
      path: "/dealer/dashboard",
      new: false,
    },
    {
      title: "applications",
      path: "/dealer/applications",
      new: false,
    },
    {
      title: "my-financing",
      path: "/dealer/financing",
      new: false,
    },
    {
      title: "inventory",
      path: "/dealer/inventory",
      new: false,
    },
    {
      title: "my leads",
      path: "/dealer/leads",
      new: true,
    },
  ];

  if (country === "NG") {
    baseMenus.push({
      title: "insurance",
      path: "/insurance",
      new: false,
    });
  }

  if (country === "KE") {
    baseMenus.push({
      title: "auctions",
      path: "/auctions",
      new: true,
    });
  }

  if (isMobile) {
    baseMenus.push(
      {
        title: "loan-calculator",
        path: "/loan-calculator",
        new: false,
      },
      {
        title: "prequalification",
        path: "/prequalification",
        new: false,
      },
      {
        title: "settings",
        path: "/settings",
        new: false,
      }
    );
  }

  return baseMenus;
};

export const adminMenus = [
  {
    title: "Manage Users",
    path: "/manage/users",
    new: false,
    country: allCountries,
  },
];
