import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import history from "@l/history";
import storage from "@l/storage";
import { useSnackbarStore } from "@/states/snackbarStore";

const client = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Accept: "application/json",
    Pragma: "no-cache",
    Expires: "0",
    "x-autochek-app": "dealerplus",
  },
  transformResponse: [(response: AxiosResponse) => response],
  timeout: 10000,
  maxRedirects: 0,
  withCredentials: false,
});

client.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = storage.getToken();
    const proxyToken = storage.getProxyUserToken();

    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }

    if (proxyToken) {
      config.headers!["x-proxy-user-token"] = proxyToken;
    }

    return config;
  },
  (error) => {
    const errorResponse = JSON.parse(error["response"]?.data);

    const showMsg = useSnackbarStore.getState().showSnackbar;
    showMsg(errorResponse?.message, "error");

    return Promise.reject(
      new Error(errorResponse?.message || "An error occurred")
    );
  }
);

client.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const showMsg = useSnackbarStore.getState().showSnackbar;
    if (!error.response) {
      showMsg("Network error. Please check your connection.", "error");

      return Promise.reject(new Error("Network error"));
    }

    if (error.response.status === 401) {
      storage.clearAll();
      history.replace("/auth/sign-in");
    }

    const errorResponse = JSON.parse(error["response"]?.data);

    showMsg(errorResponse?.message, "error");

    return Promise.reject(
      new Error(errorResponse?.message || "An error occurred")
    );
  }
);

export default client;
