import { DEALER_FINANCE_COUNTRIES } from "@/utils/finance-status";

const allCountries = ["NG", "KE", "UG", "GH", "CI", "SN"];

export const menus = [
  {
    title: "dashboard",
    path: "/dashboard",
    new: false,
    country: allCountries,
  },
  {
    title: "applications",
    path: "/applications",
    new: false,
    country: allCountries,
  },
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
    country: allCountries,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
    country: allCountries,
  },
];

export const otherRoleMenus = [
  {
    title: "dashboard",
    path: "/dashboard",
    new: false,
    country: allCountries,
  },
  {
    title: "applications",
    path: "/applications",
    new: false,
    country: allCountries,
  },
  {
    title: "inventory",
    path: "/inventory",
    new: false,
    country: allCountries,
  },
  {
    title: "my-dealers",
    path: "/manage/dealers",
    new: true,
    country: allCountries,
  },
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
    country: allCountries,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
    country: allCountries,
  },
];

export const dealerDsaMenus = [
  {
    title: "dashboard",
    path: "/dashboard",
    new: false,
    country: allCountries,
  },
  {
    title: "applications",
    path: "/applications",
    new: false,
    country: allCountries,
  },
  {
    title: "inventory",
    path: "/inventory",
    new: false,
    country: allCountries,
  },
  {
    title: "insurance",
    path: "/insurance",
    new: false,
    country: ["NG"],
  },
  {
    title: "my-dealers",
    path: "/manage/dealers",
    new: true,
    country: allCountries,
  },
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
    country: allCountries,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
    country: allCountries,
  },
];

export const otherRoleMenusDF = () => {
  const baseMenus = [
    {
      title: "dashboard",
      path: "/dashboard",
      new: false,
      country: allCountries,
    },
    {
      title: "applications",
      path: "/applications",
      new: false,
      country: allCountries,
    },
    {
      title: "financing",
      path: "/financing",
      new: false,
      country: DEALER_FINANCE_COUNTRIES,
    },
    {
      title: "inventory",
      path: "/inventory",
      new: false,
      country: allCountries,
    },
    {
      title: "insurance",
      path: "/insurance",
      new: true,
      country: ["NG"],
    },
    {
      title: "my-dealers",
      path: "/manage/dealers",
      new: true,
      country: allCountries,
    },
    {
      title: "loan-calculator",
      path: "/loan-calculator",
      new: false,
      country: allCountries,
    },
    {
      title: "prequalification",
      path: "/prequalification",
      new: false,
      country: allCountries,
    },
  ];

  return baseMenus;
};

export const dealerFinanceManagerMenu = [
  {
    title: "dashboard",
    path: "/dashboard",
    new: false,
    country: allCountries,
  },
  {
    title: "financing",
    path: "/financing",
    new: true,
    country: DEALER_FINANCE_COUNTRIES,
  },
  {
    title: "inventory",
    path: "/inventory",
    new: false,
    country: allCountries,
  },
  {
    title: "loan-calculator",
    path: "/loan-calculator",
    new: false,
    country: allCountries,
  },
  {
    title: "prequalification",
    path: "/prequalification",
    new: false,
    country: allCountries,
  },
];

export const franchiseMenus = () => {
  const baseMenus = [
    {
      title: "dashboard",
      path: "/dealer/dashboard",
      new: false,
      country: allCountries,
    },
    {
      title: "applications",
      path: "/dealer/applications",
      new: false,
      country: allCountries,
    },
    {
      title: "my-financing",
      path: "/dealer/financing",
      new: false,
      country: DEALER_FINANCE_COUNTRIES,
    },
    {
      title: "inventory",
      path: "/dealer/inventory",
      new: false,
      country: allCountries,
    },
    {
      title: "insurance",
      path: "/insurance",
      new: false,
      country: ["NG"],
    },
    {
      title: "auctions",
      path: "/auctions",
      new: false,
      country: ["KE"],
    },
    {
      title: "my-leads",
      path: "/dealer/leads",
      new: true,
      country: ["NG", "GH", "KE"],
    },
    {
      title: "loan-calculator",
      path: "/loan-calculator",
      new: false,
      country: allCountries,
    },
    {
      title: "prequalification",
      path: "/prequalification",
      new: false,
      country: allCountries,
    },
    {
      title: "settings",
      path: "/settings",
      new: false,
      country: allCountries,
    },
  ];

  return baseMenus;
};

export const adminMenus = [
  {
    title: "Manage Users",
    path: "/manage/users",
    new: false,
    country: allCountries,
  },
];
