import { ReactNode, useContext } from "react";
import { GlobalTagContext } from "./gtmGlobalContext";

declare global {
  interface Window {
    dataLayer: any;
  }
}

interface GTMTagging {
  trackEvent(event: string, data: {}): void;
  trackPageView(data: {}): void;
}

interface GTMTrackEventProps {
  children: ReactNode;
  event: string;
  data?: {};
  id?: string;
}

const pushDataLayer = (event: string, data: {}) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer?.push({
    event,
    ...data,
  });
};

const useGTMTagging = (): GTMTagging => {
  const { pageDataLayer: globalTags } = useContext(GlobalTagContext);

  return {
    trackEvent: (event: string, data = {}) =>
      pushDataLayer(event, { ...globalTags, ...data }),
    trackPageView: (data = {}) =>
      pushDataLayer("Pageview", { ...globalTags, ...data }),
  };
};
export const GTMTrackEvent = ({
  children,
  event,
  data = {},
  id,
}: GTMTrackEventProps) => {
  const { trackEvent } = useGTMTagging();

  return (
    <a id={id ?? event} onClick={(e) => trackEvent(event, data)}>
      {children}
    </a>
  );
};

export default useGTMTagging;
