import { lazy, useEffect, useState } from "react";
import clarity from "@microsoft/clarity";
import { useTranslation } from "react-i18next";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
} from "react-router-dom";
import shallow from "zustand/shallow";
import { isMobile } from "react-device-detect";

import RequireAuthentication from "@c/require-authentication";
import AdaptiveAsideLayout from "@lo/adaptive-aside";
import history from "@l/history";
import storage from "@l/storage";
import Redirect from "@p/redirect";
import asideState from "./states/aside";
import authState from "./states/auth";

import ForgotPassword from "@p/auth/forgot-password";
import ResetPassword from "@p/auth/reset-password";
import SignIn from "@p/auth/sign-in";
import VerifyLink from "@p/auth/verify-link";
import NotFound from "@p/errors/not-found";
import SnackbarVariant from "./components/blocks/Snackbar/SnackbarVariant";

//components
const Dashboard = lazy(() => import("@/pages/dashboard"));
const Applications = lazy(() => import("./pages/applications"));
const InventoryDetails = lazy(() => import("./pages/applications/[carId]"));
const LoanDetails = lazy(() => import("./pages/applications/[id]"));
const CreateCarForCashForm = lazy(
  () => import("./pages/applications/car-for-cash-form")
);
const CreateCarForm = lazy(() => import("./pages/applications/car-form"));
const CarFound = lazy(() => import("./pages/applications/car-found"));
const CarList = lazy(() => import("./pages/applications/car-list"));
const Confirmation = lazy(() => import("./pages/applications/confirmation"));
const DealDetails = lazy(() => import("./pages/applications/deal-id"));
const EditInventoryDetails = lazy(
  () => import("./pages/applications/edit/[carId]")
);
const Loans = lazy(() => import("./pages/applications/loans"));
const OfferSimulator = lazy(
  () => import("./pages/applications/offer-simulator")
);
const SelectCars = lazy(() => import("./pages/applications/select-car"));
const SimulationHistory = lazy(
  () => import("./pages/applications/simulation-history")
);
const UploadDocuments = lazy(
  () => import("./pages/applications/upload-documents")
);
const UploadSignedDocuments = lazy(
  () => import("./pages/applications/upload-signed-documents")
);
const TotalCommission = lazy(
  () => import("./pages/dashboard/total-commission")
);
const TotalSales = lazy(() => import("./pages/dashboard/total-sales"));
const DealerApplication = lazy(() => import("./pages/dealerApplication"));
const DealerBenefits = lazy(() => import("./pages/dealerBenefits"));
const DealerDashboard = lazy(() => import("./pages/dealerDashboard"));
const DealerFinancing = lazy(() => import("./pages/dealerFinancing"));
const DealerHelp = lazy(() => import("./pages/dealerHelp"));
const DealerHistory = lazy(() => import("./pages/dealerHistory"));
const Inventory = lazy(() => import("./pages/inventory"));
const LoanCalculatorPage = lazy(() => import("./pages/loan-calculator"));
const PreQualification = lazy(
  () => import("./pages/prequalification/prequalification")
);
const AllRepoDeals = lazy(() => import("./pages/repo/all"));
const RepoFinancing = lazy(() => import("./pages/repo/repoFinancing"));
const LoanLimitForm = lazy(
  () => import("./pages/applications/loan-limit-form")
);
const FinanceApplicationForm = lazy(
  () => import("./pages/applications/import-financing")
);
const DealerLimitConfirmation = lazy(
  () => import("./pages/applications/dealer-limit-confirmation")
);
const DealerLoanDetails = lazy(() => import("./pages/applications/loan-id"));
const CreditLimitActivation = lazy(
  () => import("./pages/applications/credit-limit-activation")
);
const UploadMasterAgreement = lazy(
  () => import("./pages/applications/upload-master-agreement")
);
const RepoFinancingForm = lazy(
  () => import("./pages/applications/repo-financing")
);
const RepoInventory = lazy(() => import("./pages/repo-inventory"));
const DealerInventory = lazy(() => import("./pages/dealer-inventory"));
const PreRepoFinancingForm = lazy(() => import("./components/refix/PreRepoFi"));
const UploadDealerDocuments = lazy(
  () => import("./pages/applications/upload-dealer-documents")
);
const UploadDealerSignedDocuments = lazy(
  () => import("./pages/applications/upload-dealer-signed-docs")
);
const RequestInspection = lazy(
  () => import("./pages/applications/request-inspection")
);
const UploadEquity = lazy(() => import("./pages/applications/upload-equity"));
const UploadCustomDocument = lazy(
  () => import("./pages/applications/custom-document")
);
const ValuationReport = lazy(
  () => import("./pages/applications/valuation-report")
);
const UploadPpiDocument = lazy(() => import("./pages/applications/upload-ppi"));
const ManageUsersModule = lazy(() => import("./pages/manage-users"));
const LoanTransferForm = lazy(
  () => import("./pages/applications/loan-transfer-form")
);
const CarTrackerDocument = lazy(
  () => import("./pages/applications/car-tracker")
);
const Financing = lazy(() => import("./pages/financing"));
const SelectDealer = lazy(() => import("./pages/select-dealer"));
const SettingsPage = lazy(() => import("./pages/settings"));
const SellInsurance = lazy(() => import("./pages/insurance/sell-insurance"));
const QuoteForm = lazy(() => import("./pages/insurance/quote-form"));
const InsurancePolicies = lazy(
  () => import("./pages/insurance/insurance-policies")
);
const InsuranceDetail = lazy(
  () => import("./layouts/insurance-layouts/InsuranceDetail")
);
const QuoteGenerated = lazy(() => import("./pages/insurance/quote-generated"));
const QuoteFormDetails = lazy(
  () => import("./pages/insurance/quote-form-details")
);
const Auctions = lazy(() => import("./pages/auctions"));
const ActivateDirectDebit = lazy(
  () => import("./pages/applications/direct-debit")
);
const InsuranceDirectDebit = lazy(
  () => import("./pages/insurance/insurance-direct-debit")
);
const InsurancePaymentCompleted = lazy(
  () => import("./layouts/insurance-layouts/InsurancePaymentCompleted")
);
const InsurancePayment = lazy(
  () => import("./pages/insurance/insurance-payment-confirmed")
);
const DealerLeads = lazy(() => import("./pages/leads"));
const DealerLeadDetails = lazy(() => import("./pages/leads/[id]"));
const RouteNavigationOverlay = lazy(
  () => import("./components/home/RouteNavOverlay")
);
const DealerListModule = lazy(
  () => import("./components/dealerLeads/DealerList")
);
const CarInsurance = lazy(() => import("./pages/insurance/car-insurance"));

const App = () => {
  const { i18n } = useTranslation();
  const isFranchise = storage.utilities.isFranchise();
  const isAdmin = storage.utilities.isAdmin();
  const [setVisibility, setCollapsibility] = asideState(
    (state) => [state.setVisibility, state.setCollapsibility],
    shallow
  );
  const [myHistory, setMyHistory] = useState<boolean>(false);

  const toViewHistory = () => {
    isMobile ? history.push(`/dealer/history`) : setMyHistory(true);
  };

  document
    .getElementsByTagName("html")[0]
    .setAttribute("dir", `${i18n.language === "ar" ? "rtl" : "ltr"}`);

  const isLoggedIn =
    authState((state: IAuthState) => state.isLoggedIn) ||
    storage?.getUser() !== null;

  useEffect(() => {
    if (isLoggedIn && isLoggedIn !== null) {
      setVisibility(true);
      setCollapsibility(true);
    } else {
      setVisibility(false);
      setCollapsibility(false);
    }
  }, [isLoggedIn, setCollapsibility, setVisibility]);

  useEffect(() => {
    clarity.init(process.env.REACT_APP_CLARITY_API_KEY!);
  }, []);

  const roleBasedPath = () => {
    switch (true) {
      case isFranchise:
        return "/dealer/dashboard";
      case isAdmin:
        return "/manage/users";
      default:
        return "/dashboard";
    }
  };

  return (
    <>
      <HistoryRouter history={history}>
        <Routes>
          <Route path="auth">
            <Route path="sign-in" element={<SignIn />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="verify-link" element={<VerifyLink />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<AdaptiveAsideLayout />}>
            <Route path="/" element={<Redirect path={roleBasedPath()} />} />
            <Route
              path="dashboard"
              element={
                <RequireAuthentication>
                  <Dashboard />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/dashboard"
              element={
                <RequireAuthentication>
                  <DealerDashboard
                    myHistory={myHistory}
                    setMyHistory={setMyHistory}
                    toViewHistory={toViewHistory}
                  />
                </RequireAuthentication>
              }
            />
            <Route
              path="manage/users"
              element={
                <RequireAuthentication>
                  <ManageUsersModule />
                </RequireAuthentication>
              }
            />
            <Route
              path="manage/routes"
              element={
                <RequireAuthentication>
                  <RouteNavigationOverlay />
                </RequireAuthentication>
              }
            />
            <Route
              path="manage/dealers"
              element={
                <RequireAuthentication>
                  <DealerListModule />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/applications"
              element={
                <RequireAuthentication>
                  <DealerApplication />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/history"
              element={
                <RequireAuthentication>
                  <DealerHistory />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/financing"
              element={
                <RequireAuthentication>
                  <DealerFinancing />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/loan-limit"
              element={
                <RequireAuthentication>
                  <LoanLimitForm />
                </RequireAuthentication>
              }
            />

            <Route
              path="dealer/financing/loan"
              element={
                <RequireAuthentication>
                  <FinanceApplicationForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/financing/repo-financing"
              element={
                <RequireAuthentication>
                  <RepoFinancingForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/loan-limit/confirmation"
              element={
                <RequireAuthentication>
                  <DealerLimitConfirmation />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/credit-limit"
              element={
                <RequireAuthentication>
                  <CreditLimitActivation />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/upload-master-agreement"
              element={
                <RequireAuthentication>
                  <UploadMasterAgreement />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/benefits"
              element={
                <RequireAuthentication>
                  <DealerBenefits />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/help"
              element={
                <RequireAuthentication>
                  <DealerHelp />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/best-deals"
              element={
                <RequireAuthentication>
                  <RepoFinancing />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/financing/guide"
              element={
                <RequireAuthentication>
                  <PreRepoFinancingForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/inventory"
              element={
                <RequireAuthentication>
                  <DealerInventory />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/inventory/:carId"
              element={
                <RequireAuthentication>
                  <RepoInventory />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/all-deals"
              element={
                <RequireAuthentication>
                  <AllRepoDeals />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/leads"
              element={
                <RequireAuthentication>
                  <DealerLeads />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/leads/:id"
              element={
                <RequireAuthentication>
                  <DealerLeadDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications"
              element={
                <RequireAuthentication>
                  <Applications />
                </RequireAuthentication>
              }
            />
            <Route
              path="financing"
              element={
                <RequireAuthentication>
                  <Financing />
                </RequireAuthentication>
              }
            />
            <Route
              path="financing/select-dealer"
              element={
                <RequireAuthentication>
                  <SelectDealer />
                </RequireAuthentication>
              }
            />
            <Route
              path="loan-calculator"
              element={
                <RequireAuthentication>
                  <LoanCalculatorPage />
                </RequireAuthentication>
              }
            />
            <Route
              path="prequalification"
              element={
                <RequireAuthentication>
                  <PreQualification />
                </RequireAuthentication>
              }
            />
            <Route
              path="inventory"
              element={
                <RequireAuthentication>
                  <Inventory />
                </RequireAuthentication>
              }
            />
            <Route
              path="inventory/:carId"
              element={
                <RequireAuthentication>
                  <InventoryDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="inventory/create-car"
              element={
                <RequireAuthentication>
                  <CreateCarForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="inventory/:carId/edit"
              element={
                <RequireAuthentication>
                  <EditInventoryDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="dashboard/total-commission"
              element={
                <RequireAuthentication>
                  <TotalCommission />
                </RequireAuthentication>
              }
            />
            <Route
              path="dashboard/total-sales"
              element={
                <RequireAuthentication>
                  <TotalSales />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/loan-transfer"
              element={
                <RequireAuthentication>
                  <LoanTransferForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/loans"
              element={
                <RequireAuthentication>
                  <Loans />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/loans/:id"
              element={
                <RequireAuthentication>
                  <LoanDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/dealer/applications/loans/:id"
              element={
                <RequireAuthentication>
                  <DealDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="/dealer/applications/deal/:id"
              element={
                <RequireAuthentication>
                  <DealerLoanDetails />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/select-cars"
              element={
                <RequireAuthentication>
                  <SelectCars />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance"
              element={
                <RequireAuthentication>
                  <InsurancePolicies />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/:id"
              element={
                <RequireAuthentication>
                  <InsuranceDetail />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/car"
              element={
                <RequireAuthentication>
                  <CarInsurance />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/sell"
              element={
                <RequireAuthentication>
                  <SellInsurance />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/quote-form"
              element={
                <RequireAuthentication>
                  <QuoteForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="insurance/quote-completed/:id"
              element={
                <RequireAuthentication>
                  <QuoteGenerated />
                </RequireAuthentication>
              }
            />

            <Route
              path="insurance/quote-form-details"
              element={
                <RequireAuthentication>
                  <QuoteFormDetails />
                </RequireAuthentication>
              }
            />

            <Route
              path="insurance/payment"
              element={
                <RequireAuthentication>
                  <InsurancePayment />
                </RequireAuthentication>
              }
            />

            <Route
              path="insurance/payment-completed"
              element={
                <RequireAuthentication>
                  <InsurancePaymentCompleted />
                </RequireAuthentication>
              }
            />

            <Route
              path="insurance/direct-debit"
              element={
                <RequireAuthentication>
                  <InsuranceDirectDebit />
                </RequireAuthentication>
              }
            />

            <Route
              path="applications/create-car-for-cash"
              element={
                <RequireAuthentication>
                  <CreateCarForCashForm />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/car-found"
              element={
                <RequireAuthentication>
                  <CarFound />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/loan/confirmation"
              element={
                <RequireAuthentication>
                  <Confirmation />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/upload-documents"
              element={
                <RequireAuthentication>
                  <UploadDocuments />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/upload-signed-documents"
              element={
                <RequireAuthentication>
                  <UploadSignedDocuments />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/applications/upload-documents"
              element={
                <RequireAuthentication>
                  <UploadDealerDocuments />
                </RequireAuthentication>
              }
            />
            <Route
              path="dealer/applications/upload-signed-documents"
              element={
                <RequireAuthentication>
                  <UploadDealerSignedDocuments />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/car-list"
              element={
                <RequireAuthentication>
                  <CarList />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/offer-simulator"
              element={
                <RequireAuthentication>
                  <OfferSimulator />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/offer-simulation-history"
              element={
                <RequireAuthentication>
                  <SimulationHistory />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/request-inspection"
              element={
                <RequireAuthentication>
                  <RequestInspection />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/car-tracker"
              element={
                <RequireAuthentication>
                  <CarTrackerDocument />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/equity-payment"
              element={
                <RequireAuthentication>
                  <UploadEquity />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/direct-debit"
              element={
                <RequireAuthentication>
                  <ActivateDirectDebit />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/custom-document"
              element={
                <RequireAuthentication>
                  <UploadCustomDocument />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/valuation-report"
              element={
                <RequireAuthentication>
                  <ValuationReport />
                </RequireAuthentication>
              }
            />
            <Route
              path="applications/ppi-request"
              element={
                <RequireAuthentication>
                  <UploadPpiDocument />
                </RequireAuthentication>
              }
            />
            <Route
              path="settings"
              element={
                <RequireAuthentication>
                  <SettingsPage />
                </RequireAuthentication>
              }
            />
            <Route
              path="auctions"
              element={
                <RequireAuthentication>
                  <Auctions />
                </RequireAuthentication>
              }
            />
          </Route>
          <Route element={<AdaptiveAsideLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </HistoryRouter>

      <SnackbarVariant />
    </>
  );
};

export default App;
