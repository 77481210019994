import { DEALER_FINANCE_COUNTRIES } from "@/utils/finance-status";
import type { MenuProps } from "antd";
import { Link } from "react-router-dom";

export interface IMenuProps {
  t: (key: string) => string;
  country: string;
  role: string;
}

export interface INavMenus {
  title: string;
  path: string;
  new: boolean;
  country: string[];
  subMenus?: MenuProps["items"];
}

export const itemStyle = {
  color: "#30345e",
  fontWeight: "600",
};

export const mainMenus = ({ t, country, role }: IMenuProps): INavMenus[] => {
  const inventoryItems: MenuProps["items"] = [
    {
      label: (
        <Link
          to={"/inventory"}
          style={itemStyle}
          className="capitalize md:uppercase"
        >
          {t("inventory-management")}
        </Link>
      ),
      key: "2",
    },
  ];

  if (DEALER_FINANCE_COUNTRIES.includes(country)) {
    inventoryItems.unshift(
      {
        label: (
          <Link
            to={"/financing"}
            style={itemStyle}
            className="capitalize md:uppercase"
          >
            {t("my-financing")}
          </Link>
        ),
        key: "1",
      },
      {
        type: "divider",
      }
    );
  }

  const sellItems: MenuProps["items"] = [
    {
      label: (
        <Link
          to={"/applications"}
          style={itemStyle}
          className="capitalize md:uppercase"
        >
          {t("customer-financing")}
        </Link>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link
          to={"/loan-calculator"}
          style={itemStyle}
          className="capitalize md:uppercase"
        >
          {t("loan-calculator")}
        </Link>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link
          to={"/prequalification"}
          style={itemStyle}
          className="capitalize md:uppercase"
        >
          {t("prequalification")}
        </Link>
      ),
      key: "2",
    },
  ];

  if (country === "NG" && role === "ACCOUNT_MANAGER") {
    sellItems.unshift(
      {
        label: (
          <Link
            to={"/insurance"}
            style={itemStyle}
            className="capitalize md:uppercase"
          >
            {t("insurance")}
          </Link>
        ),
        key: "3",
      },
      {
        type: "divider",
      }
    );
  }

  const marketingItems: MenuProps["items"] = [];

  if (
    ["NG", "KE", "GH"].includes(country) &&
    role !== "DEALER_DSA" &&
    role !== "ACCOUNT_MANAGER"
  ) {
    marketingItems.unshift({
      label: (
        <Link
          to={"/dealer/leads"}
          style={itemStyle}
          className="capitalize md:uppercase"
        >
          {t("my-leads")}
        </Link>
      ),
      key: "1",
    });
  }

  const supportItems: MenuProps["items"] = [];

  if (role === "ACCOUNT_MANAGER" || role === "DEALER_DSA") {
    supportItems.unshift({
      label: (
        <Link
          to={"/manage/dealers"}
          style={itemStyle}
          className="capitalize md:uppercase"
        >
          {t("my-dealers")}
        </Link>
      ),
      key: "1",
    });
  }

  return [
    {
      title: "Home",
      path: "/dashboard",
      new: false,
      country: ["NG", "KE", "UG", "GH", "CI", "SN"],
    },
    {
      title: "inventory",
      path: "",
      new: false,
      country: ["NG", "KE", "UG", "GH", "CI", "SN"],
      subMenus: inventoryItems,
    },
    {
      title: "sell",
      path: "",
      new: false,
      country: ["NG", "KE", "UG", "GH", "CI", "SN"],
      subMenus: sellItems,
    },
    {
      title: "marketing",
      path: "",
      new: false,
      country: ["NG", "KE", "GH"],
      subMenus: marketingItems,
    },
    {
      title: "support",
      path: "",
      new: false,
      country: ["NG", "KE", "UG", "GH", "CI", "SN"],
      subMenus: supportItems,
    },
  ];
};
