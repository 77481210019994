import tw from "tailwind-styled-components";

const Layout = tw``;

const Container = tw.div``;

const Main = tw.div`
${(props: ILayoutMain) => props.$isAsideVisible && "ml-0 duration-500"}
flex-1 lg:py-12 lg:px-8 px-3.5 xl:px-2 py-8 overflow-scroll no-scrollbar bg-[#F8FAFC] mt-12 mb-10 pb-24 min-h-screen h-auto`;

const Footer = tw.div`
flex items-center justify-between px-3.5 py-4 bg-purple-900 w-full  border-t-[1px] border-[#E5E5E5]
`;

Layout.Container = Container;
Layout.Main = Main;
Layout.Footer = Footer;

export default Layout;
