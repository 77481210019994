import { useEffect, useState } from "react";
import {
  Container,
  Content,
  Logo,
  FormContainer,
  CustomSelect,
} from "./styles/sign-in";
import { Button, Form, Input, message, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useVerifyCode } from "@/hooks/use-authentication";
import { AxiosError } from "axios";
import storage from "@l/storage";
import { useTranslation } from "react-i18next";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const VerifyLink = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { mutate, isLoading, isError, isSuccess, error, data } =
    useVerifyCode();

  useEffect(() => {
    document.title = "Dealer Plus | Verify Code";
  }, []);

  const location = useLocation();
  const { i18n } = useTranslation("navigation");
  const { t: t1 } = useTranslation("auth");
  const [lang, setLang] = useState("");
  const queryParam = new URLSearchParams(window.location.search);
  const locale: string | any = queryParam.get("lang");

  const handleLanguageChange = (value: string) => {
    const params = new URLSearchParams({ lang: value });
    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true }
    );
    i18n.changeLanguage(value);
    setLang(value);
  };

  useEffect(() => {
    i18n.changeLanguage(locale);
    setLang(i18n.language);
  }, [i18n, i18n.language, locale]);

  useEffect(() => {
    isError &&
      error instanceof AxiosError &&
      isError &&
      error instanceof AxiosError &&
      form.resetFields();
  }, [isError, form, error]);

  useEffect(() => {
    if (isSuccess) {
      const parsedData = JSON.parse(data.data);
      const user = parsedData.user;
      const roles = parsedData.authority.roles;
      const permissions = parsedData.authority.permissions;
      const country = parsedData.user.country;
      storage.setUser(user);
      storage.setRoles(roles);
      storage.setPermissions(permissions);
      storage.setCountry(country);
      message.success("Verification code validated successfully");
      navigate("/auth/reset-password", { replace: true });
    }
  }, [isSuccess, navigate, data]);

  const handleVerifyCode = (values: IVerifyCode) => {
    mutate({ otp: values.otp });
  };

  return (
    <Container>
      <Content>
        <Logo>
          <img
            alt="Autochek's Logo"
            src="https://media.autochek.africa/file/publicAssets/logo-horizontal-1.svg"
          />
          <div
            className="chevron-back"
            onClick={() => navigate("/auth/forgot-password")}
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/Icon-1.svg"
              alt="chevron-back"
            />
          </div>
        </Logo>
        <FormContainer>
          <Form
            name="verify-link"
            layout="vertical"
            requiredMark={false}
            onFinish={handleVerifyCode}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label={t1("verification-code")}
              name="otp"
              rules={[
                {
                  required: true,
                  message: t1("please-enter-verification-code"),
                },
              ]}
            >
              <Input
                className="input-field"
                placeholder={t1("enter-verify-code")}
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit">
                {isLoading ? (
                  <Loader variant={"secondary"} />
                ) : (
                  t1("verify-code")
                )}
              </Button>
            </Form.Item>
          </Form>
        </FormContainer>
      </Content>
      <CustomSelect>
        <div className="language-img">
          <img
            src="https://media.autochek.africa/file/publicAssets/icons8-language.svg"
            alt="language-img"
          />
        </div>
        <Select
          onChange={handleLanguageChange}
          value={lang}
          options={[
            { value: "en", label: "English" },
            { value: "fr", label: "Français" },
            { value: "ar", label: "عربي" },
          ]}
        />
      </CustomSelect>
    </Container>
  );
};

export default VerifyLink;
