import { Alert, Snackbar } from "@mui/material";
import { useSnackbarStore } from "@/states/snackbarStore";

const SnackbarVariant = () => {
  const { open, hideSnackbar, message, severity } = useSnackbarStore();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={hideSnackbar}
    >
      <Alert severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarVariant;
