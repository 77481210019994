import { INavMenus, newFranchiseMenus } from "@/data/menus/newMenus";
import { adminMenus, menus } from "@/data/menu-navs";
import { mainMenus } from "@/data/menus/mainMenu";

interface ITargetMenuProps {
  isAdmin: boolean;
  isFranchise: boolean;
  isAccountManager: boolean;
  isDealerDSA: boolean;
  isDealerFinanceManager: boolean;
  country: string;
  t: (key: string) => string;
  role: string;
}

const getTargetMenus = ({
  isAdmin,
  isFranchise,
  isAccountManager,
  isDealerDSA,
  isDealerFinanceManager,
  country,
  t,
  role,
}: ITargetMenuProps): INavMenus[] => {
  if (isAdmin) return adminMenus;
  if (isFranchise) return newFranchiseMenus({ t, country });
  if (isAccountManager) return mainMenus({ t, country, role });
  if (isDealerFinanceManager && !isAccountManager)
    return mainMenus({ t, country, role });
  if (isDealerDSA) return mainMenus({ t, country, role });

  return menus;
};

export default getTargetMenus;
