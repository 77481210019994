import React, { useState } from "react";
import { Drawer } from "antd";
import storage from "@l/storage";
import { useNavigate } from "react-router-dom";
import authState from "../../states/auth";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Loader } from "@/pages/auth/styles/loader";
import { resetCarForm } from "@/pages/applications/car-form/helper";
import handleRevertUser from "@/utils/revert-users";
import { NewMenuIcon } from "@/assets/svgs";
import useGTMTagging from "@/utils/gtmTagging";
import {
  adminMenus,
  dealerDsaMenus,
  dealerFinanceManagerMenu,
  franchiseMenus,
  menus,
  otherRoleMenus,
  otherRoleMenusDF,
} from "@/data/menus/mobile-menus";
import { DEALER_FINANCE_COUNTRIES } from "@/utils/finance-status";

interface IMobile {
  openMobile: boolean;
  onCloseMobile: () => void;
}

const MobileNav = ({ openMobile, onCloseMobile }: IMobile) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isFranchise = storage.utilities.isFranchise();
  const isAccountManager = storage.utilities.isAccountManager();
  const isDealerDSA = storage.utilities.isDealerDsa();
  const isDealerFinanceManager = storage.utilities.isDealerFinanceManager();
  const isAdmin = storage.utilities.isAdmin();
  const setLogin = authState((state: IAuthState) => state.setLogin);
  const { t } = useTranslation("navigation");
  const [loading, setLoading] = useState(false);
  const isAssumedUser = storage.getAssumedUser();
  const country = storage.getCountry();
  const isDealerFinancingEnabled = DEALER_FINANCE_COUNTRIES.includes(country);

  const { trackEvent, trackPageView } = useGTMTagging();

  let navMenus;

  if (isFranchise) {
    navMenus = franchiseMenus();
  } else if (isDealerFinanceManager && !isAccountManager) {
    navMenus = dealerFinanceManagerMenu;
  } else if (isAccountManager && isDealerFinancingEnabled) {
    navMenus = otherRoleMenusDF();
  } else if (isAccountManager) {
    navMenus = otherRoleMenus;
  } else if (isDealerDSA) {
    navMenus = dealerDsaMenus;
  } else if (isAdmin) {
    navMenus = adminMenus;
  } else {
    navMenus = menus;
  }

  const handleSignOut = () => {
    trackPageView({ session_end: new Date().toString() });
    trackEvent("logout", { session_end: new Date().toString() });
    queryClient.clear();
    storage.clearAll();
    resetCarForm();
    setLogin(false);
    navigate("/auth/sign-in", { replace: true });
  };

  return (
    <Drawer
      placement="left"
      onClose={onCloseMobile}
      open={openMobile}
      closable={false}
      rootClassName="mobile-nav-drawers"
      maskStyle={{ padding: 0 }}
    >
      <div className="mt-16 flex flex-col divide-y">
        {navMenus.map(
          (menu) =>
            menu?.country?.includes(country) && (
              <button
                key={menu.title}
                className="flex items-center gap-1 capitalize text-base font-semibold text-[#30345e] py-4 w-full"
                onClick={() => {
                  navigate(`${menu?.path}`, { replace: true });
                  onCloseMobile();
                }}
              >
                {t(menu?.title)}
                {menu.new && <NewMenuIcon />}
              </button>
            )
        )}

        <div>
          <button
            onClick={handleSignOut}
            className="flex items-center gap-2 font-semibold text-[#30345e] text-base py-4"
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-1.svg"
              alt="Logout"
            />{" "}
            <div className="m-nav-item">{t("logout")}</div>
          </button>
        </div>

        {isAssumedUser && (
          <div className="py-4">
            <button
              className="text-sm bg-[#ffb619] text-[#30345e] font-semibold rounded-[44px] min-w-[126px] w-auto h-[32px] px-3 flex items-center"
              onClick={() =>
                handleRevertUser({ setLoading, navigate, onCloseMobile })
              }
            >
              {loading ? <Loader variant="secondary" /> : "REVERT USER"}
            </button>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default MobileNav;
