import { List, message, Tabs, Tag } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import storage from "@/lib/storage";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import type { PaginationProps } from "antd";
import { paramsObjectToQueryString } from "@/utils/paramsObjectToString";
import { NotifyTab } from "./styles";
import styled from "styled-components";
import NotificationCard from "@/components/NotificationCard";
import { CheckCircleOutlined } from "@ant-design/icons";
import { AlertMessagesResponse } from "@/interface/inventory-alerts";
import {
  readDealerNotification,
  readInventoryNotification,
} from "@/hooks/use-api";

interface IActivePage {
  currentPage: number;
  pageSize: number;
}

type props = {
  notificationsResponse: INotificationsResponse;
  loadNotification: boolean;
  activePage: IActivePage;
  setActivePage: (a: IActivePage) => void;
  inAppNotification: INotificationsResponse;
  inAppLoadNotification: boolean;
  inAppActivePage: IActivePage;
  setInAppActivePage: (a: IActivePage) => void;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
  inventoryNotif: AlertMessagesResponse;
  loadInventoryNofif: boolean;
  activeInventoryPage: IActivePage;
  setActiveInventoryPage: (a: IActivePage) => void;
};

const Message = tw.div`text-sm font-semibold`;
const Title = tw.div`font-semibold text-base px-4 py-3 bg-[#eeefff] text-[#30345e]`;

const NotificationList = styled.div`
  .ant-list .ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0;
    color: rgba(0, 0, 0, 0.88);
  }

  @media (min-width: 768px) {
    .group:hover .group-hover\:opacity-100 {
      opacity: 1;
    }
    .md\:opacity-0 {
      opacity: 0;
    }
    .md\:group-hover\:opacity-100 {
      opacity: 0;
    }
  }
`;

const NotificationView = ({
  notificationsResponse,
  loadNotification,
  activePage,
  setActivePage,
  inAppNotification,
  inAppLoadNotification,
  inAppActivePage,
  setInAppActivePage,
  setShowNotification,
  inventoryNotif,
  loadInventoryNofif,
  activeInventoryPage,
  setActiveInventoryPage,
}: props) => {
  const user = storage.getUser();
  const queryClient = useQueryClient();
  const { t } = useTranslation("navigation");
  const { t: t1 } = useTranslation("dashboard");
  const isFranchise = storage.utilities.isFranchise();

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <button className="bg-[#ffb619] font-semibold rounded px-2">
          {t1("previous")}
        </button>
      );
    }
    if (type === "next") {
      return (
        <button className="bg-[#ffb619] font-semibold rounded px-2">
          {t1("next")}
        </button>
      );
    }
    return originalElement;
  };

  const handleMarkAsRead = async (notificationId: string) => {
    const payload = {
      id: notificationId,
      dealer_id: user?.company?.id,
      is_read: true,
    };
    try {
      await readDealerNotification(user?.company?.id, payload);
      message.success("Message has being read");
      queryClient.invalidateQueries(["fetch_notifications"]);
    } catch (error: any) {}
  };

  const handleMarkAsReadForInventory = async (id: string) => {
    const payload = {};
    try {
      await readInventoryNotification(id, payload);
      queryClient.invalidateQueries(["fetch_inventory_notifs"]);
    } catch (error) {}
  };

  const renderCustomerLoanNotification = () => {
    return (
      <NotificationList>
        <List
          dataSource={inAppNotification?.notifications}
          loading={inAppLoadNotification}
          pagination={{
            current: inAppActivePage?.currentPage,
            pageSize: inAppActivePage?.pageSize,
            total: inAppNotification?.pagination?.total,
            onChange(page, pageSize) {
              setInAppActivePage({
                ...inAppActivePage,
                currentPage: page,
              });
              paramsObjectToQueryString({
                current_page: page,
                page_size: pageSize,
              });
            },
            itemRender: itemRender,
            position: "bottom",
            align: "center",
            simple: true,
          }}
          renderItem={(item) => (
            <List.Item>
              <NotificationCard
                item={item}
                setShowNotification={setShowNotification}
              />
            </List.Item>
          )}
        />
      </NotificationList>
    );
  };

  const renderDealerLoanNotification = () => {
    return (
      <List
        dataSource={notificationsResponse?.notifications}
        loading={loadNotification}
        pagination={{
          current: activePage?.currentPage,
          pageSize: activePage?.pageSize,
          total: notificationsResponse?.pagination?.total,
          onChange(page, pageSize) {
            setActivePage({
              ...activePage,
              currentPage: page,
            });
            paramsObjectToQueryString({
              current_page: page,
              page_size: pageSize,
            });
          },
          itemRender: itemRender,
          position: "bottom",
          align: "center",
          simple: true,
        }}
        renderItem={(item) => (
          <List.Item className="!py-0">
            <div className="flex gap-1.5 w-full px-3 py-4 md:px-4 hover:bg-slate-100 group">
              <div className="flex-1">
                <Message
                  className={`${
                    item?.isRead
                      ? "font-medium text-[#8f8f8f]"
                      : "font-semibold text-[#30345E]"
                  }`}
                >
                  {item?.message}
                </Message>
              </div>
              {item?.isRead === false && (
                <div className="xl:opacity-0 xl:group-hover:opacity-100 transition-opacity duration-300">
                  <button
                    onClick={() => handleMarkAsRead(item?.id)}
                    className="bg-white text-[#30345e] whitespace-nowrap hover:text-white hover:bg-[#30345e] border border-solid border-[#30345e] text-xs flex items-center gap-1 px-2 h-[24px] font-semibold rounded-[4px]"
                  >
                    <CheckCircleOutlined />
                    Mark as read
                  </button>
                </div>
              )}
            </div>
          </List.Item>
        )}
      />
    );
  };

  const renderInventoryAlerts = () => {
    return (
      <List
        dataSource={inventoryNotif?.alertMessages}
        loading={loadInventoryNofif}
        pagination={{
          current: activeInventoryPage?.currentPage,
          pageSize: activeInventoryPage?.pageSize,
          total: inventoryNotif?.pagination?.total,
          onChange(page, pageSize) {
            setActiveInventoryPage({
              ...activeInventoryPage,
              currentPage: page,
            });
            paramsObjectToQueryString({
              page_number: page,
              page_size: pageSize,
            });
          },
          itemRender: itemRender,
          position: "bottom",
          align: "center",
          simple: true,
        }}
        renderItem={(item) => (
          <List.Item className="!py-0">
            <div className="flex gap-1.5 w-full px-3 py-4 md:px-4 hover:bg-slate-100 group">
              <div className="flex-1">
                <Message>{item?.content}</Message>
                <Tag color="geekblue" className="uppercase mt-2">
                  {item?.category?.replaceAll("_", " ")}
                </Tag>
              </div>
              <div className="xl:opacity-0 xl:group-hover:opacity-100 transition-opacity duration-300">
                <button
                  onClick={() => handleMarkAsReadForInventory(item?.id)}
                  className="bg-white text-[#30345e] whitespace-nowrap hover:text-white hover:bg-[#30345e] border border-solid border-[#30345e] text-xs flex items-center gap-1 px-2 h-[24px] font-semibold rounded-[4px]"
                >
                  <CheckCircleOutlined />
                  Mark as read
                </button>
              </div>
            </div>
          </List.Item>
        )}
      />
    );
  };

  return (
    <div className="fixed xl:absolute top-[56px] xl:top-[48px] left-0 xl:left-auto right-[6px] xl:right-0 bg-white rounded-lg min-w-full xl:min-w-[519px] w-full xl:w-auto h-auto md:h-auto overflow-hidden shadow-[0_0px_15px_rgba(0,0,0,0.1)]">
      <div className="notify-container px-0 pt-0 pb-[24px] max-h-fit h-[552px] overflow-y-scroll">
        <Title>{t("notifications")}</Title>

        <NotifyTab>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: `Customer Loans`,
                key: "1",
                children: renderCustomerLoanNotification(),
              },
              ...(isFranchise
                ? [
                    {
                      label: `Dealer Loans`,
                      key: "2",
                      children: renderDealerLoanNotification(),
                    },
                  ]
                : []),
              {
                label: `Moderations`,
                key: "3",
                children: renderInventoryAlerts(),
              },
            ]}
          />
        </NotifyTab>
      </div>
    </div>
  );
};

export default NotificationView;
