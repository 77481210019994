import { useEffect, useState } from "react";
import { Button, Form, Input, message, Select } from "antd";
import {
  Container,
  Content,
  Logo,
  FormContainer,
  ForgotPasswordLink,
  CustomSelect,
} from "./styles/sign-in";
import { useNavigate, useLocation } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useResetLink } from "@/hooks/use-authentication";
import { AxiosError } from "axios";
import storage from "@l/storage";
import { useTranslation } from "react-i18next";

const getBorderClass = (props: Partial<IButtonLoader>) => {
  if (props.variant === "primary") {
    return "border-b-white";
  } else if (props.variant === "secondary") {
    return "border-b-button-variants-secondary-loading-scene";
  } else if (props.variant === "tertiary") {
    return "border-b-button-variants-tertiary-loading-scene";
  } else {
    return undefined;
  }
};

const Loader = tw.div`
  ${(props: Partial<IButtonLoader>) => getBorderClass(props)}
  inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { mutate, isLoading, isError, isSuccess, error, data } = useResetLink();

  const { i18n } = useTranslation("navigation");
  const { t: t1 } = useTranslation("auth");
  const [lang, setLang] = useState("");
  const queryParam = new URLSearchParams(window.location.search);
  const locale = queryParam.get("lang") as string;

  useEffect(() => {
    document.title = "Dealer Plus | Reset Link";
  }, []);

  const handleLanguageChange = (value: string) => {
    const params = new URLSearchParams({ lang: value });
    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true }
    );
    i18n.changeLanguage(value);
    setLang(value);
  };

  useEffect(() => {
    i18n.changeLanguage(locale);
    setLang(i18n.language);
  }, [i18n, i18n.language, locale]);

  useEffect(() => {
    isError &&
      error instanceof AxiosError &&
      isError &&
      error instanceof AxiosError &&
      form.resetFields();
  }, [isError, form, error]);

  useEffect(() => {
    if (isSuccess) {
      const parsedData = JSON.parse(data?.data);
      const token = parsedData?.token;
      storage.setToken(token);
      message.success("Verification code has been sent to your mail");
      navigate("/auth/verify-link", { replace: true });
    }
  }, [isSuccess, navigate, data]);

  const handleResetLink = (values: IResetLink) => {
    mutate({ payload: values.payload });
  };

  return (
    <Container>
      <Content>
        <Logo>
          <img
            alt="Autochek's Logo"
            src="https://media.autochek.africa/file/publicAssets/logo-horizontal-1.svg"
          />
          <button
            className="chevron-back"
            onClick={() => navigate("/auth/sign-in")}
          >
            <img
              src="https://media.autochek.africa/file/publicAssets/Icon-1.svg"
              alt="chevron-back"
            />
          </button>
        </Logo>
        <FormContainer>
          <Form
            name="reset-link"
            layout="vertical"
            requiredMark={false}
            onFinish={handleResetLink}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label={t1("enter-your-registered-email")}
              name="payload"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: t1("please-provide-your-email"),
                },
              ]}
            >
              <Input
                className="input-field"
                placeholder={t1("email-address")}
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit">
                {isLoading ? (
                  <Loader variant={"secondary"} />
                ) : (
                  t1("send-reset-link")
                )}
              </Button>
            </Form.Item>
          </Form>
          <ForgotPasswordLink onClick={() => navigate("/auth/sign-in")}>
            {t1("login")}
          </ForgotPasswordLink>
        </FormContainer>
      </Content>
      <CustomSelect>
        <div className="language-img">
          <img
            src="https://media.autochek.africa/file/publicAssets/icons8-language.svg"
            alt="language-img"
          />
        </div>
        <Select
          onChange={handleLanguageChange}
          value={lang}
          options={[
            { value: "en", label: "English" },
            { value: "fr", label: "Français" },
            { value: "ar", label: "عربي" },
          ]}
        />
      </CustomSelect>
    </Container>
  );
};

export default ForgotPassword;
